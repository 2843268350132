<template>
  <div class="animated fadeIn">
    <b-btn v-if="isAdmin" @click="editMode = !editMode" :variant="editModeVariant" class="mb-4" title="Click to toggle" block>{{ editModeText }} Mode <span>(Click to toggle)</span></b-btn>
    
    <!-- NEW FILE FORM -->
    <b-card v-if="editMode" no-body>
	    <h4 slot="header">File Upload</h4>
	    <b-card-body>
		    <b-form @submit.prevent="postMedia" @reset="postMediaReset" class="animated fadeIn">
			    <b-form-row class="mb-2">
            <b-col cols="12" sm="2" class="mb-2"><b-select :options="supportCategoryOpts" v-model="fileNewData.category" name="category"></b-select></b-col>
			      <b-col><b-file name="file" v-model="fileNewData.location" placeholder="File" /></b-col>
			    </b-form-row>
			    <b-form-row>
			      <b-col cols="12" sm="3" class="mb-2"><b-input name="label" v-model="fileNewData.label" placeholder="File Label" /></b-col>
			      <b-col><b-input name="description" v-model="fileNewData.description" placeholder="File Description" /></b-col>
			    </b-form-row>
		      <b-form-row>
		        <b-col cols="12" sm="8" class="mb-8">&nbsp;</b-col>
		        <b-col><b-btn variant="success" type="submit" block><i class="fa fa-upload"></i> Upload</b-btn></b-col>
		        <b-col><b-btn variant="danger" type="reset" block><i class="fa fa-refresh"></i> Reset</b-btn></b-col>
		      </b-form-row>
		    </b-form>
      </b-card-body>
    </b-card>
    
    <b-card-group columns>
	      <b-card v-for="category in supportCategoryOpts" v-if="category.value && category.value !=='new' && category.value !=='void'" no-body :key="category.value">
		      <h5 slot="header" header-tag="header" class="my-0">
		      
		        <span v-if="editMode" class="animated fadeIn">
		          <b-btn size="sm" 
		          @click="iniCatEdit(category.value)" variant="primary"><i 
		          class="fa fa-pencil"></i></b-btn>&nbsp;<b-btn
		          size="sm" 
		          @click="deleteCategory(category.value)" variant="danger"><i 
		          class="fa fa-remove"></i></b-btn> &nbsp; 
	          </span>
	          
	          {{ category.text.toUpperCase() }}
		      </h5>
		      
	        <!-- b-card-body class="text-info text-center">
	          We could say something about each category
	        </b-card-body -->
		      
		      <b-list-group flush>
			      <b-list-group-item v-for="file in supportFiles" v-if="file.category_id === category.value" :key="file.id"><h5 class="mb-0">
              
                     
              <span v-if="editMode" class="animated fadeIn">
                
				        <b-btn size="sm" 
				        @click="fileEdit(file.id)" variant="primary"><i 
				        class="fa fa-pencil"></i></b-btn>&nbsp;<b-btn 
	              size="sm" 
	              @click="deleteMedia(file.id)" variant="danger"><i 
	              class="fa fa-remove"></i></b-btn><!-- &nbsp;<b-btn 
	              size="sm" 
	              :href="file.location" target="_blank" variant="primary"><i 
	              class="fa fa-file"></i></b-btn>&nbsp; --> &nbsp; 
              </span>
              
              <a target="_blank" :href="file.location">{{ file.label }}</a></h5>
			        <div v-if="file.description" class="pt-2"><span class="text-dark">{{ file.description }}</span></div>
			      </b-list-group-item>
		      </b-list-group>
	      </b-card>
    </b-card-group>
    
    <b-modal ref="categoryForm" v-model="categoryFormShow" title="Category">
      <b-container fluid>
        <b-form-row>
          <b-col><b-input name="label" v-model="categoryFormData.text" placeholder="Category Label" /></b-col>
        </b-form-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
         <b-button-group class="float-right">
           <b-btn size="sm" variant="success" @click="postCategory()">
             Save This Category
           </b-btn>
	         <b-btn size="sm" variant="warning" @click="categoryFormReset()">
	           Cancel
	         </b-btn>
         </b-button-group>
       </div>
    </b-modal>
    
    <b-modal ref="fileEditForm" v-model="fileEditFormhow" title="File">
      <b-container fluid>
        <b-form-row class="mb-2">
          <b-col><b-select :options="supportCategoryOpts" v-model="fileEditData.category_id" name="category"></b-select></b-col>
          <b-col><b-input name="label" v-model="fileEditData.label" placeholder="File Label" /></b-col>
        </b-form-row>
        <b-form-row>
          <b-col><b-input name="description" v-model="fileEditData.description" placeholder="File Description" /></b-col>
        </b-form-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-button-group class="float-right">
          <b-btn size="sm" variant="success" @click="putMedia()">
            Save This File
          </b-btn>
          <b-btn size="sm" variant="warning" @click="fileEditFormhow=false">
            Cancel
          </b-btn>
        </b-button-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const defaultNewFileData = {
  category: null,
  label: null,
  location: {},
  description: null
}
const defaultCategoryFormData = {
    text: null
  }
const IRI_MEDIA = '/api/media_objects/'
const IRI_CATEGORIES = '/api/support_categories/'

export default {
  name: 'support-center',
  data () {
    return {
      editMode: false,
      fileNewData: {
        category: null,
        label: null,
        location: {},
        description: null
      },
      fileEditData: {
        category: null
      },
      categoryFormData: {
        text: null
      },
      categoryFormShow: false,
      fileEditFormhow: false,
      iteratedCategories: []
    }
  },
  computed: {
    ...mapGetters(['supportCategoryOpts','supportFiles']),
    editModeVariant () {
      return (this.editMode) ? 'warning' : ''
    },
    editModeText () {
      return (this.editMode) ? 'Edit' : 'View'
    }
  },
  methods: {
    fileEdit (id) {
      this.fileEditData = Object.assign({}, this.supportFiles[id])
      this.$refs.fileEditForm.show()
    },
    checkFleNewData () {
      if (!this.fileNewData.location.name) {
        alert('please select a file')
        return false
      }
      if (!this.fileNewData.category) {
        alert('please select a category')
        return false
      }
      if (!this.fileNewData.label) {
        alert('please add a label for the media')
        return false
      }
      return true
    },
    // categories
    categoryFormReset () {
      this.categoryFormShow=false
      this.categoryFormData = Object.assign({}, defaultCategoryFormData)
    },
    iniCatEdit (id) {
      this.categoryFormData = Object.assign({}, this.supportCategoryOpts[id])
      this.$refs.categoryForm.show()
    },
    putCategory () {
      if (!this.categoryFormData.value) { alert('none selected') }
      this.$store.dispatch('putSupportCategory', {
        id: this.categoryFormData.value,
        data: {
          labelText: this.categoryFormData.text
        }
      }).then(() => {
        // this.$store.dispatch('getSupportCategories')
        this.categoryFormReset()
      })
    },
    postCategory () {
      if (this.categoryFormData.value) {
        this.putCategory()
      } else {
        this.$store.dispatch('postSupportCategory', {
          labelText: this.categoryFormData.text
        }).then(resp => {
          this.fileNewData.category = resp.id
          this.$store.dispatch('getSupportCategories')
          this.categoryFormShow=false
          this.categoryFormReset()
        })
      }
    },
    deleteCategory (id) {
      if (!confirm('remove "' + this.supportCategoryOpts[id].text + '" category?')) return
      // check for files and sllow relocation
      for (let k in this.supportFiles) {
        if (this.supportFiles[k].category_id === id) {
          alert('Please relocate the associated files first!')
          return
        }
      }
      this.$store.dispatch('deleteSupportCategory', id).then(() => {
        
      }, error => {
        console.log(error)
      })
    },
    // media
    postMedia (evt) {
      evt.preventDefault()
      if (!this.checkFleNewData()) {
        return
      }
      this.$store.dispatch('postMedia', {
        file: this.fileNewData.location,
        slug: 'support',
        user: this.currentUser.id
      }).then(response => {
        this.$store.dispatch('postSupportMedia', {
          media: IRI_MEDIA + response.id,
          category: IRI_CATEGORIES + this.fileNewData.category,
          mediaLabel: this.fileNewData.label,
          mediaDescription: this.fileNewData.description
        }).then(() => {
          this.postMediaReset()
          // this.$store.dispatch('getSupportMedia')
        }, error => {
          console.log('error with support media insert')
        })
      }, error => {
        console.log('error with media upload')
      })
    },
    putMedia () {
      this.$store.dispatch('putSupportMedia', {
        id: this.fileEditData.id,
        data: {
          media: IRI_MEDIA + this.fileEditData.media_id,
          category: IRI_CATEGORIES + this.fileEditData.category_id,
          mediaLabel: this.fileEditData.label,
          mediaDescription: this.fileEditData.description
        }
      }).then(() => {
        this.$store.dispatch('getSupportMedia')
        this.fileEditFormhow=false
      })
    },
    deleteMedia (id) {
      if (!confirm('remove "' + this.supportFiles[id].label + '"?')) return
      this.$store.dispatch('deleteSupportMedia', id)
    },
    postMediaReset () {
      this.fileNewData = Object.assign({}, defaultNewFileData)
    }
  },
  watch: {
    'fileNewData.category' (val) {
      if (val === 'new') {
        this.$refs.categoryForm.show()
      }
    },
    'fileNewData.location' (val) {
      if (val && typeof val.name !== 'undefined') {
        // update this.fileNewData.label with default file name
        let fileParts = val.name.split('.')
        let name = fileParts.slice(0, -1).join(' ').replace(/[^0-9a-z]/gi, ' ')
        let label = name.toLowerCase().split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
        let ext = fileParts[fileParts.length -1].toUpperCase()
        this.fileNewData.label = label + ' (' + ext + ')'
      }
    },
    categoryFormShow (val) {
      if (val) {
        this.fileNewData.category = null
      }
    }
  },
  created () {
    this.categoryFormData = { ...defaultCategoryFormData }
    this.fileNewData = { ...defaultNewFileData }
    this.$store.dispatch('getSupportCategories')
    this.$store.dispatch('getSupportMedia', { pagination: false })
  },
  destroyed () {
    this.$store.dispatch('resetSupportMedia')
    this.$store.dispatch('resetSupportCategories')
  }
}
</script>

<style lang="scss">
.card-columns {
  column-count: 2;
}
@media only screen and (max-width: 1024px) {
  .card-columns {
    column-count: 1;
  }
}
</style>